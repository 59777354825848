export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    linkedValues: {
      type: Object,
      default: () => {},
    },
    dependingElementsMatch: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    isValid() {
      return (
        !this.required ||
        this.readonly ||
        (this.required && this.isValueValid(this.value))
      );
    },
    linkedValue() {
      if (this.linkedValues && Object.keys(this.linkedValues).length === 1) {
        return Object.values(this.linkedValues)[0];
      }
      return null;
    },
  },
  methods: {
    isValueValid(val) {
      if (val?.length === 0) {
        return false;
      }

      if (this.isNumeric(val)) {
        return val >= 0;
      }

      return true;
    },
    isNumeric(val) {
      return !isNaN(val) && !isNaN(parseFloat(val));
    },
  },
  watch: {
    linkedValue(newVal) {
      if (newVal) {
        this.$emit('input', newVal);
      }
    },
    dependingElementsMatch(newVal) {
      if (newVal === true) {
        this.$emit('input', this.linkedValue);
      }
    },
  },
};
