<template>
  <div class="input-align">
    <ui5-label
      class="modal-input pb-0"
      v-bind="labelRules"
      wrapping-type="Normal"
    >
      {{ label }}
    </ui5-label>
    <b-row no-gutters>
      <b-col cols="6">
        <ui5-radio-button
          class="modal-input modal-radio"
          :value-state="isValid ? '' : 'Error'"
          v-bind="inputButton1Rules"
          @change="$emit('input', $event.target.value)"
        />
      </b-col>
      <b-col cols="6">
        <ui5-radio-button
          class="modal-input modal-radio"
          :value-state="isValid ? '' : 'Error'"
          v-bind="inputButton2Rules"
          @change="$emit('input', $event.target.value)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import inputMixin from '../mixins/input-mixin';

const BooleanInput = Vue.component('BooleanInput', {
  mixins: [inputMixin],
  props: {
    textButton1: {
      type: String,
      default: '',
    },
    textButton2: {
      type: String,
      default: '',
    },
    selectedButton1: {
      type: Boolean,
      default: false,
    },
    selectedButton2: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputButton1Rules() {
      return {
        name: this.name,
        text: this.textButton1,
        value: 'true',
        checked: this.value ? this.value === 'true' : this.selectedButton1,
        required: this.required,
        readonly: this.readonly,
      };
    },
    inputButton2Rules() {
      return {
        name: this.name,
        text: this.textButton2,
        value: 'false',
        checked: this.value ? this.value === 'false' : this.selectedButton2,
        required: this.required,
        readonly: this.readonly,
      };
    },
    labelRules() {
      return {
        for: this.name,
        required: this.required,
      };
    },
  },
});

export default BooleanInput;
</script>

<style scoped>
.modal-input {
  display: block;
}
</style>
